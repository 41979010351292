import React from "react";
import Cardproduct from "../../Component/cardproduct/Cardproduct";
import malta from "../../Media/Product/Ladera/Malta";
import tuscan from "../../Media/Product/Ladera/Tuscan";
import data from "./data.json";
import "./Cascada.scss";

const Laderaproduct = () => {
  const imageMap = {
    malta,
    tuscan,
  };

  return (
    <div className="laderacontainer">
      {data.cards.map((card, index) => {
        return (
          <Cardproduct
            key={index}
            src={imageMap[card.imagesKey]}
            title={card.title}
            sub={card.sub}
            style={card.style}
            details={card.details}
            price={card.price}
            whatsappLink={card.whatsappLink}
            tourLink={card.tourLink}
          />
        );
      })}
    </div>
  );
};

export default Laderaproduct;
