import React from "react";

import aira from "../../Media/Product/Cascada/Aira";
import manoa from "../../Media/Product/Cascada/Manoa";
import keila from "../../Media/Product/Cascada/Keila";
import victoria from "../../Media/Product/Cascada/Victoria";
import madeira from "../../Media/Product/Cascada/Madeira";
import alexandra from "../../Media/Product/Cascada/Alexandra";
import data from "./data.json";
import Cardproductone from "../../Component/cardproduct/Cardproductone.jsx";
import "./Cascada.scss";

const Cascadaproduct = () => {
  const imageMap = {
    aira,
    manoa,
    keila,
    victoria,
    madeira,
    alexandra,
  };

  return (
    <div className="laderacontainer">
      {data.cards.map((card, index) => {
        return (
          <Cardproductone
            key={index}
            src={imageMap[card.imagesKey]}
            title={card.title}
            sub={card.sub}
            style={card.style}
            details={card.details}
            price={card.price}
            whatsappLink={card.whatsappLink}
            tourLink={card.tourLink}
          />
        );
      })}
    </div>
  );
};

export default Cascadaproduct;
