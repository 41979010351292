import aira1 from "./Aira-2.webp";
import aira2 from "./Aira-3.webp";
import aira3 from "./Aira-5.webp";
import aira4 from "./Aira-7.webp";
import aira5 from "./Aira-8.webp";
import aira6 from "./Aira-9.webp";
import aira7 from "./Aira-10.webp";
import aira8 from "./Aira-11.webp";
import aira9 from "./Aira-11.webp";
import aira10 from "./Aira-12.webp";

const aira = [
  aira1,
  aira2,
  aira3,
  aira4,
  aira5,
  aira6,
  aira7,
  aira8,
  aira9,
  aira10,
];

export default aira;
