import tuscan1 from "./Tuscan-1.webp";
import tuscan2 from "./Tuscan-2.webp";
import tuscan3 from "./Tuscan-3.webp";
import tuscan4 from "./Tuscan-4.webp";
import tuscan5 from "./Tuscan-5.webp";
import tuscan6 from "./Tuscan-6.webp";
import tuscan7 from "./Tuscan-7.webp";
import tuscan8 from "./Tuscan-8.webp";
import tuscan9 from "./Tuscan-9.webp";
import tuscan10 from "./Tuscan-10.webp";
import tuscan11 from "./Tuscan-11.webp";
import tuscan12 from "./Tuscan-12.webp";
import tuscan13 from "./Tuscan-13.webp";
import tuscan14 from "./Tuscan-14.webp";
import tuscan15 from "./Tuscan-15.webp";
import tuscan16 from "./Tuscan-16.webp";
import tuscan17 from "./Tuscan.webp";

const tuscan = [
  tuscan17,
  tuscan1,
  tuscan2,
  tuscan3,
  tuscan4,
  tuscan5,
  tuscan5,
  tuscan6,
  tuscan7,
  tuscan8,
  tuscan9,
  tuscan10,
  tuscan11,
  tuscan12,
  tuscan13,
  tuscan14,
  tuscan15,
  tuscan16,
  tuscan17,
];

export default tuscan;
