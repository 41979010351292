import React from "react";
import "./Promo.scss";
import promoimg from "../../Media/GDC-South-Jakarta-Template-1024-x-768-px-7-1.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Promo = ({ data }) => {
  const wapromo = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285179737090&text=Halo%20Daffa,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Grand%20Duta%20City%20%20https://granddutacitysoj-marketing.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="promocontainer">
      <div className="promoimg">
        <img alt="promoimg" src={promoimg} className="img" />
      </div>
      <div className="promocontent">
        <div className="title">Promo yang Tersedia</div>
        <div className="promoitem">
          {data.items.map((item, index) => (
            <div className="item" key={index}>
              <FontAwesomeIcon icon={faCheckDouble} />
              &nbsp;&nbsp;{item}
            </div>
          ))}
        </div>
        <div className="promobutton">
          <button onClick={wapromo} className="promowa">
            <FontAwesomeIcon icon={faWhatsapp} /> Dapatkan Promo Terbaik Anda
          </button>
        </div>
      </div>
    </div>
  );
};

export default Promo;
