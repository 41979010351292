import React, { useEffect, useState } from "react";
import "./Cardhome.scss";
import { Link } from "react-router-dom";
const mediaMatch = window.matchMedia("(min-width: 768px)");

const Cardhome = ({
  src,
  title,
  description,
  price,
  subdes,
  button,
  style,
  link,
}) => {
  const [isDesktop, setIsDesktop] = useState(mediaMatch.matches);
  useEffect(() => {
    const handler = (e) => setIsDesktop(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  }, []);

  const dynamicStyle = {
    ...style,
    flexDirection: isDesktop ? style.flexDirection : "column",
  };
  return (
    <div className="cardhomecont" style={dynamicStyle}>
      <div className="cardimage">
        <img alt="gdc" src={src} className="img" />
      </div>
      <div className="cardcontent">
        <div className="title">{title}</div>
        <div className="des">{description}</div>
        <div className="price">{price}</div>
        <div className="des">{subdes}</div>
        <Link to={link}>
          <button className="cardbutton">{button}</button>
        </Link>
      </div>
    </div>
  );
};

export default Cardhome;
