import React from "react";

import "./Hero.scss";
const Hero = ({ text, subtext, bg }) => {
  return (
    <div className="herocontainer" style={{ backgroundImage: `url(${bg})` }}>
      <div className="textstyle">
        <div className="herotext">
          Welcome To
          <br />
          {text}
        </div>
        <div className="herosubtext">{subtext}</div>
      </div>
    </div>
  );
};

export default Hero;
