import React, { useEffect, useState } from "react";
import "./Cardproduct.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faBed,
  faCar,
  faHouse,
  faShower,
} from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
const mediaMatch = window.matchMedia("(min-width: 768px)");

const Cardproductone = ({
  title,
  sub,
  style,
  src,
  details,
  price,
  productwa,
  whatsappLink,
  tourLink,
}) => {
  const settings = {
    dots: src.length > 1,
    arrows: src.length > 1,
    infinite: src.length > 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: src.length > 1,
  };

  const [isDesktop, setIsDesktop] = useState(mediaMatch.matches);
  useEffect(() => {
    const handler = (e) => setIsDesktop(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  }, []);

  const dynamicStyle = {
    ...style,
    flexDirection: isDesktop ? style.flexDirection : "column",
  };

  return (
    <div className="cardprod" style={dynamicStyle}>
      <div className="imgcard">
        <Slider {...settings}>
          {src.map((image, index) => (
            <img
              key={index}
              className="image"
              src={image}
              alt={`Launching ${index + 1}`}
            />
          ))}
        </Slider>
      </div>
      <div className="cardcontent">
        <div className="cardtitle">{title}</div>
        <div className="cardsub">{sub}</div>
        <hr />
        <div className="cardicon">
          <span>
            <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
            &nbsp;: {details.luas}&nbsp;
          </span>
          <span>
            <FontAwesomeIcon icon={faHouse} />
            &nbsp;: {details.rumah}&nbsp;
          </span>
          <span>
            <FontAwesomeIcon icon={faBed} />
            &nbsp;: {details.kamar}&nbsp;
          </span>
          <span>
            <FontAwesomeIcon icon={faShower} />
            &nbsp;: {details.mandi}&nbsp;
          </span>
          <span>
            <FontAwesomeIcon icon={faCar} />
            &nbsp;: {details.car}&nbsp;
          </span>
        </div>
        <div className="cardprice">
          {price}
          <br />
          Juta/Bulan
        </div>
        <div className="cardbutton">
          <button
            onClick={() => window.open(whatsappLink, "_blank")}
            className="buttonwa"
          >
            Whatsapp
          </button>
          <button
            onClick={() => window.open(tourLink, "_blank")}
            className="buttonwa"
            style={{
              display: tourLink ? "inline-block" : "none", // Hide button when no link
            }}
          >
            Virtual Tour
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cardproductone;
