import React from "react";
import "./Footer.scss";

import logo from "../../Media/Logo/Logo.png";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer">
        <div className="footer-content">
          <div className="footer-logo">
            <img alt="footerlogo" src={logo} className="logo" />
          </div>
          <div className="footer-description">
            <div className="footer-title">Marketing Gallery</div>
            <div className="footer-subtitle">
              Grand Duta City - South of Jakarta
            </div>

            <div className="footer-alamat">
              Jl. Raya Parung No.47, Jabon Mekar, Kec. Parung, Kabupaten Bogor,
              Jawa Barat 16330
              <br />
              Contact Marketing : +6285179737090
            </div>
          </div>
        </div>
        <div className="footer-close"> Grand Duta City - South of Jakarta</div>
      </div>
    </div>
  );
};

export default Footer;
