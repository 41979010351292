import "./App.css";
import Navbar from "./Component/Navbar/Navbar";
import Cascada from "./Pages/Cascada/Cascada";
import Home from "./Pages/Home/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Ladera from "./Pages/Ladera/Ladera";
import ScrollToTop from "./Component/ScrollToTop";
import { FloatingWhatsApp } from "react-floating-whatsapp";

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/cascada" element={<Cascada />} />
          <Route path="/ladera" element={<Ladera />} />
        </Routes>
      </Router>
      <a
        target="_blank"
        href="https://api.whatsapp.com/send/?phone=6285179737090&text=Halo%20Daffa,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Grand%20Duta%20City%20https://granddutacitysoj-marketing.com/&type=phone_number&app_absent=0"
      >
        <FloatingWhatsApp />
      </a>
    </div>
  );
}

export default App;
