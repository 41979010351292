import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import logo from "../../Media/Logo/Logo.png";
import logomobile from "../../Media/Logo/Untitled-750-x-350-px.png";
import "./Navbar.scss";
import { Link } from "react-router-dom";

const Navbar = () => {
  const wanavbar = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285179737090&text=Halo%20Daffa,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Grand%20Duta%20City%20https://granddutacitysoj-marketing.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="navbarcontainer">
      <div className="navbarlogo">
        <Link to="/">
          <img alt="gdclogo" src={logo} className="logo" />
        </Link>
        <Link to="/">
          <img alt="gdclogomobile" src={logomobile} className="mobilelogo" />
        </Link>
      </div>
      <div className="navbarbutton">
        <button onClick={wanavbar} className="navbarb">
          <FontAwesomeIcon icon={faWhatsapp} />
          &nbsp; Whatsapp
        </button>
      </div>
    </div>
  );
};

export default Navbar;
