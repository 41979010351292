import alexandra1 from "./Alexandra.webp";
import alexandra2 from "./Alexandra-2.webp";
import alexandra3 from "./Alexandra-3.webp";
import alexandra4 from "./Alexandra-4.webp";
import alexandra5 from "./Alexandra-5.webp";
import alexandra6 from "./Alexandra-7.webp";
import alexandra7 from "./Alexandra-8.webp";
import alexandra8 from "./Alexandra-9.webp";
import alexandra9 from "./Alexandra-10.webp";
import alexandra10 from "./Alexandra-11.webp";
import alexandra11 from "./Alexandra-12.webp";
import alexandra12 from "./13.webp";

const alexandra = [
  alexandra1,
  alexandra2,
  alexandra3,
  alexandra4,
  alexandra5,
  alexandra6,
  alexandra7,
  alexandra8,
  alexandra9,
  alexandra10,
  alexandra11,
  alexandra12,
];

export default alexandra;
