import malta1 from "./Malta.webp";
import malta2 from "./Malta-2.webp";
import malta3 from "./Malta-3.webp";
import malta4 from "./Malta-4.webp";
import malta5 from "./Malta-5.webp";
import malta6 from "./Malta-6.webp";
import malta7 from "./Malta-7.webp";
import malta8 from "./Malta-8.webp";
import malta9 from "./Malta-9.webp";
import malta10 from "./Malta-10.webp";
import malta11 from "./Malta-11.webp";
import malta12 from "./Malta-12.webp";
import malta13 from "./Malta-13.webp";
import malta14 from "./Malta-14.webp";
import malta15 from "./Malta-15.webp";
import malta16 from "./Malta-16.webp";
import malta17 from "./Malta-17.webp";
import malta18 from "./Malta-18.webp";

const malta = [
  malta1,
  malta2,
  malta3,
  malta4,
  malta5,
  malta6,
  malta7,
  malta8,
  malta9,
  malta10,
  malta11,
  malta12,
  malta13,
  malta14,
  malta15,
  malta16,
  malta17,
  malta18,
];

export default malta;
