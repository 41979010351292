import React from "react";
import Cardhome from "../../Component/Cardhome/Cardhome";
import data from "./data.json";
import ladera from "../../Media/Ladera.png";
import cascada from "../../Media/Casade.png";
import "./Producthome.scss";

const Producthome = () => {
  const imageMap = {
    ladera,
    cascada,
  };

  return (
    <div className="producthomecon">
      {data.cards.map((card, index) => {
        return (
          <Cardhome
            key={index}
            src={imageMap[card.imagesKey]}
            title={card.title}
            description={card.description}
            style={card.style}
            price={card.price}
            subdes={card.subdes}
            button={card.button}
            link={card.Link}
          />
        );
      })}
    </div>
  );
};

export default Producthome;
