import React from "react";
import "./Icon.scss";
import iconbg from "../../Media/iconbg.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faPersonBiking,
  faSnowflake,
  faTree,
} from "@fortawesome/free-solid-svg-icons";

const Icon = () => {
  return (
    <div
      className="iconcontainer"
      style={{ backgroundImage: `url(${iconbg})` }}
    >
      <div className="icon">
        <div className="iconbox">
          <FontAwesomeIcon icon={faBuilding} size="lg" />
        </div>
        <div className="text">
          <div className="angka">200</div>
          <div className="des">
            HECTARES OF INTEGRATED <br /> TOWNSHIP
          </div>
        </div>
      </div>
      <div className="icon">
        <div className="iconbox">
          <FontAwesomeIcon icon={faTree} size="lg" />
        </div>
        <div className="text">
          <div className="angka">80</div>
          <div className="des">
            HECTARES OF <br /> GREENERY & PARKS
          </div>
        </div>
      </div>
      <div className="icon">
        <div className="iconbox">
          <FontAwesomeIcon icon={faPersonBiking} size="lg" />
        </div>
        <div className="text">
          <div className="angka">8</div>
          <div className="des">
            KILOMETERS OF <br /> BIKE LANE
          </div>
        </div>
      </div>
      <div className="icon">
        <div className="iconbox">
          <FontAwesomeIcon icon={faSnowflake} size="lg" />
        </div>
        <div className="text">
          <div className="angka">3</div>
          <div className="des">
            DEGREE'S <br /> COOLER AIR
          </div>
        </div>
      </div>
    </div>
  );
};

export default Icon;
