import React from "react";
import "./Benefit.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faGraduationCap,
  faHouse,
  faMapPin,
} from "@fortawesome/free-solid-svg-icons";
import map from "../../Media/map.png";

const Benefit = () => {
  return (
    <div>
      <div className="imgmap">
        <img src={map} alt="mapgdc" className="mapgdc" />
      </div>
      <div className="benefit-container">
        <div className="benefit-point">
          <div className="benefit-title">
            <FontAwesomeIcon icon={faGraduationCap} /> &nbsp;Education
          </div>
          <div className="benefit">
            <div className="point">
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;1 Menit ke Dwiwarna High School
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;3 Menit ke Muhammadiyah Boarding
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;20 Menit ke IPB University
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;20 Menit ke Pakuan University
            </div>
          </div>
        </div>
        <div className="benefit-point">
          <div className="benefit-title">
            <FontAwesomeIcon icon={faHouse} /> &nbsp;Health Care
          </div>
          <div className="benefit">
            <div className="point">
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;10 Menit ke Brawijaya Hospital
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;15 Menit ke Permata Depok Hospital
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;20 Menit ke Siloam Hospital
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;10 Menit ke Hermina Bogor
            </div>
          </div>
        </div>
        <div className="benefit-point">
          <div className="benefit-title">
            <FontAwesomeIcon icon={faBuilding} /> &nbsp;Entertainment
          </div>
          <div className="benefit">
            <div className="point">
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;10 Menit ke The Park Mall
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;20 Menit ke Kebun Raya Bogor
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;20 Menit ke Botani Square
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;25 Menit ke Jungle Land
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefit;
