import React from "react";
import logo from "../../Media/Logo/Logo.png";
import "./About.scss";

const About = ({ des }) => {
  return (
    <div className="aboutcontainer">
      <div className="center">
        <img alt="gdclogo" className="logo" src={logo} />
        <div className="des">{des}</div>
      </div>
    </div>
  );
};

export default About;
