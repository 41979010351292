import React from "react";
import Hero from "../../Component/Hero/Hero";
import bg from "../../Media/Hero/herohome.png";
import Icon from "../../Section/Iconhome/Icon";
import About from "../../Component/About/About";
import Promo from "../../Component/Promo/Promo";
import Producthome from "../../Section/producthome/Producthome";
import Surrounding from "../../Component/surrounding/Surrounding";
import Benefit from "../../Component/Benefit/Benefit";
import Footer from "../../Component/Footer/Footer";
const Home = () => {
  const promoData = {
    items: [
      "Tanpa DP",
      "5 Juta All In",
      "Free Biaya - Biaya",
      "Free Smart Home",
      "Free Smart Door Lock",
      "Free AC",
      "Free Kulkas",
      "Free Air Cooler",
      "Free TV LED",
      "Free Yamaha Fazio",
      "Free Wuling AirEV",
    ],
  };
  const des =
    "Grand Duta City South of Jakarta adalah sebuah proyek residensial di Bogor, Indonesia. Proyek ini menawarkan lingkungan yang nyaman dan strategis. Grand Duta City South of Jakarta dikembangkan oleh Duta Putra Land, sebuah pengembang real-estate yang telah berkontribusi dalam membangun sektor hunian dan komersial di berbagai wilayah-wilayah utama di Indonesia Proyek ini dikembangkan dengan konsep modern minimalis, menawarkan berbagai tipe rumah yang sesuai dengan kebutuhan. Lokasi rumah di Bogor ini strategis, didukung akses ke jalan raya dan berdekatan dengan fasilitas umum.";
  return (
    <div>
      <Hero text={"Grand Duta City"} subtext={"South of Jakarta"} bg={bg} />
      <Icon />
      <About des={des} />
      <Promo data={promoData} />
      <Producthome />
      <Surrounding />
      <Benefit />
      <Footer />
    </div>
  );
};

export default Home;
