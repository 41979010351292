import victoria1 from "./Victoria-2.webp";
import victoria2 from "./Victoria-3.webp";
import victoria3 from "./Victoria-4.webp";
import victoria4 from "./Victoria-5.webp";
import victoria5 from "./Victoria-6.webp";
import victoria6 from "./Victoria-7.webp";
import victoria7 from "./Victoria-8.webp";
import victoria8 from "./Victoria-9.webp";
import victoria9 from "./Victoria-10.webp";
import victoria10 from "./Victoria-10.webp";

const victoria = [
  victoria1,
  victoria2,
  victoria3,
  victoria4,
  victoria5,
  victoria6,
  victoria7,
  victoria8,
  victoria9,
  victoria10,
];

export default victoria;
