import React from "react";
import bg from "../../Media/Casade.png";
import Hero from "../../Component/Hero/Hero";
import About from "../../Component/About/About";
import Promo from "../../Component/Promo/Promo";
import Cascadaproduct from "../../Section/cascadaproduct/Cascadaproduct";
import Surrounding from "../../Component/surrounding/Surrounding";
import Benefit from "../../Component/Benefit/Benefit";
import Footer from "../../Component/Footer/Footer";

const Cascada = () => {
  const promoData = {
    items: [
      "Tanpa DP",
      "Free Biaya - Biaya",
      "Free Smart Home",
      "Free Smart Door Lock",
      "Free AC",
      "Free Kulkas",
      "Free Air Cooler",
      "Free TV LED",
      "Free Yamaha Fazio",
      "Free Wuling AirEV",
    ],
  };
  const des =
    "Cluster Ladera merupakan salah satu cluster yang terdapat dalam kawasan Grand Duta City.  Kata “Ladera” diambil dari bahasa Spanyol dengan dengan arti kaki gunung, dengan ini kami berharap dapat memberikan pengalaman kenyamanan serta ketenangan untuk penghuninya. Cluster Ladera di dukung dengan fasilitas mewah di dalamnya seperti Ballroom, Swimming Pool, Children Playground, Co-Working Space. ";
  return (
    <div>
      <Hero text={"Cluster Cascada"} bg={bg} />
      <About des={des} />
      <Promo data={promoData} />
      <Cascadaproduct />
      <Surrounding />
      <Benefit />
      <Footer />
    </div>
  );
};

export default Cascada;
